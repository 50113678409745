import { Component, HostListener, OnInit } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Subject } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';
import { takeUntil } from 'rxjs/operators';
import { IdentityService } from 'src/app/services/identity/identity.service';
import {
  ModifierKeys,
  Shortcut,
} from 'src/app/modules/shared/shortcut/shortcut.model';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { TerminalCommandService } from 'src/app/modules/shared/components/terminal/terminal-command.service';
import { CommandCollector } from 'src/app/commands/command-collector';
import { GuiService } from 'src/app/services/gui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isSidebarOpen = true;

  title = 'Admin';
  isLoggedIn = false;
  devConsole = false;
  shortcuts: Shortcut[] = [
    {
      key: `${ModifierKeys.Ctrl}+F12`,
      description: 'Dev console',
      command: () => {
        this.devConsole = !this.devConsole;
      },
    },
  ];

  private unsubscribe$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  async onResize() {
    this.guiService.checkWindowSize();
  }

  constructor(
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private spinner: SpinnerService,
    private sidebar: SidebarService,
    private identityService: IdentityService,
    private settings: PersonalSettingsService,
    private terminalCommands: TerminalCommandService,
    private commandCollector: CommandCollector,
    private guiService: GuiService
  ) {
    this.spinner.show();

    this.sidebar.isSidebarOpen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.isSidebarOpen = value;
      });
    this.oidcSecurityService
      .checkAuth()
      .subscribe(async (loginResponse: LoginResponse) => {
        console.log('app authenticated', loginResponse.isAuthenticated);
        if (!loginResponse.isAuthenticated) {
          console.log('start login');
          this.oidcSecurityService.authorize();
          return;
        }

        this.identityService
          .getLoggedInIdentityUser()
          .pipe(
            catchError(e => {
              throw e;
            })
          )
          .subscribe(u => {
            console.log('got user data');
            this.identityService.identityUser$.next(u);
            this.isLoggedIn = true;
          });
      });
  }

  async ngOnInit() {
    this.translateService.use(
      (await this.settings.getSetting('lang')) ||
        this.translateService.defaultLang
    );
    this.addTerminalCommands();
  }

  addTerminalCommands() {
    this.terminalCommands.addCommands(this.commandCollector.get());
  }
}
